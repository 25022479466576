<template lang="pug">
  .log-common
    .top-bar-wrapper
      TopBar(
        :show-inventory-group-filter="showInventoryGroupFilter"
        :show-shop-filter="showShopFilter"
        :shops-loading="shopsLoading"
        :inventory-groups-loading="inventoryGroupsLoading"
        :car-classes-loading="carClassesLoading"
        :accounts-loading="accountsLoading"
        :initiators="initiators"
        :shops="shops"
        :inventory-groups="inventoryGroups"
        :car-classes="carClasses"
        :types="types"
        :tableWrapperClass="tableWrapperClass"
        :filters="filters"
        :date-field="filters.dateField"
        @change-filters="changeFilters"
      )
    .logs-table-wrapper
      AppOverlayLoader(:state="logsLoading")
      .logs-table
        VueGoodTable(
          :rows="logs"
          :columns="columns()"
          :class="tableWrapperClass"
          :sort-options="{ enabled: false }"
        )
          template(v-slot:table-column="props")
            span(:data-cy="props.column.label") {{ $t(props.column.label) }}
          template(v-slot:table-row="{ column, row, formattedRow }")
            AppTextExpandable(
              v-if="column.field === 'text'"
              :key="row.id"
              :text="extractTranslatedAttribute(row)"
            )
            AppTextExpandable(
              v-else
              :key="row.id"
              :text="formattedRow[column.field]"
            )
    AppPagination(
      :current-page="pagination.current_page"
      :total="pagination.total_count"
      :per-page="pagination.per_page"
      :auto-scroll-on-pagination="{ target: `.${tableWrapperClass}` }"
      @change-pagination-data="changePagination"
    )
</template>

<script>
  // store modules
  import accountsModule from "@/config/store/accounts"
  import shopMatchingModule from "@/config/store/matching/shop"
  import inventoryGroupsModule from "@/config/store/inventory_groups"
  import carClassMatchingModule from "@/config/store/matching/car_class"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withLocale from "@/mixins/withLocale"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { router } from "@/config"
  import { map } from "lodash-es"
  import { mapGetters } from "vuex"
  import { appDebounce } from "@/helpers/common"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import "vue-good-table/dist/vue-good-table.css"
  import { extractTranslatedAttribute } from "@/helpers/common"
  import { AUTO_SET_INVENTORY_INITIATOR_TYPE, SALE_STOP_SETTING_INITIATOR_TYPE } from "@/config/constants"

  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  const shopMatchingsMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroupsManagement",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" },
    mutations: { setSorting: "SET_SORTING" }
  })

  const carClassMatchingsMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatching",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const accountsMixin = withStoreModule(accountsModule, {
    name: "accountsLogs",
    readers: { userInitiators: "items", accountsLoading: "loading" },
    actions: { fetchAccounts: "FETCH_ITEMS" },
    mutations: { setAccountsSorting: "SET_SORTING" }
  })

  export default {
    components: {
      TopBar: () => import("@/pages/Logs/TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      VueGoodTable
    },

    mixins: [
      shopMatchingsMixin,
      inventoryGroupsMixin,
      carClassMatchingsMixin,
      accountsMixin,
      withLocale,
      withToyotaOrganizationsMountedOrder
    ],

    props: {
      tableColumns: {
        type: Object
      }
    },

    created() {
      this.debouncedFetchLogs = appDebounce(() => this.fetchLogs())
    },

    async mounted() {
      // fetch logs first as main items on the page and then fetch another items (for dropdown)
      await this.fetchLogs()
      // dropdown items
      if (this.showInventoryGroupFilter) {
        this.fetchInventoryGroups({ pagination: { _disabled: true } })
      }
      if (this.showShopFilter) {
        this.fetchShops({ pagination: { _disabled: true } })
      }
      this.fetchCarClasses({ pagination: { _disabled: true } })
      this.setAccountsSorting({ field: "account_id", type: "asc" })
      this.fetchAccounts({ pagination: { _disabled: true } })
    },

    computed: {
      isSharedInventoryEnabled,

      ...mapGetters({ otaInitiators: "translatedOtaList" }),

      mappedUserInitiators() {
        return map(this.userInitiators, ({ id, account_id: name }) => ({ id, name, type: "User" }))
      },

      orderedOtaInitiators() {
        const collator = new Intl.Collator(this.currentLocale)
        return [...this.otaInitiators].sort((a, b) => collator.compare(a.name, b.name))
      },

      autoSetInventoryInitiator() {
        return {
          type: AUTO_SET_INVENTORY_INITIATOR_TYPE,
          _skip: true,
          name: this.$t("inventory_management.inventory_for_future.auto_set_inventory_for_future_days")
        }
      },

      saleStopSettingInitiator() {
        return {
          id: SALE_STOP_SETTING_INITIATOR_TYPE,
          type: SALE_STOP_SETTING_INITIATOR_TYPE,
          _skip: true,
          _only_by_type: true,
          name: this.$t("logs.inventory_log.initiator.sale_stop_settings")
        }
      },

      isUserActionLogTabInUse() {
        return router.currentRoute.name === "UserActionLog"
      },

      showInventoryGroupFilter() {
        return this.isSharedInventoryEnabled
      },

      showShopFilter() {
        return this.isUserActionLogTabInUse || !this.isSharedInventoryEnabled
      }
    },

    methods: {
      extractTranslatedAttribute,

      changeFilters(filters) {
        this.applyFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchLogs()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchLogs()
      },

      extractInitiators({ add_auto_set_inventory, add_sale_stop_settings }) {
        const initiators = [...this.orderedOtaInitiators]
        if (add_auto_set_inventory) {
          initiators.push(this.autoSetInventoryInitiator)
        }
        if (add_sale_stop_settings) {
          initiators.push(this.saleStopSettingInitiator)
        }
        initiators.push(...this.mappedUserInitiators)

        return map(initiators, initiatorData => ({ ...initiatorData, type: initiatorData.type || "Ota" }))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/vue-good-table.sass"

  .log-common
    margin-top: 30px

    ::v-deep
      .logs-table
        +app-vue-table

        &-td
          max-width: 300px

        &-td:not(:first-of-type), &-th
          white-space: nowrap

    .top-bar-wrapper
      min-height: 150px
      margin-bottom: 25px

  .logs-table-wrapper
    position: relative

  @media (min-width: 1370px)
    .logs-table
      +listing-container(154px)

  @media (min-width: 1200px) and (max-width: 1370px)
    .logs-table
      +listing-container(205px)

  @media (min-width: 992px) and (max-width: 1200px)
    .logs-table
      +listing-container(205px)

  @media (min-width: 950px) and (max-width: 992px)
    .logs-table
      +listing-container(205px)

  @media (max-width: 950px)
    .logs-table
      +listing-container(280px)
</style>
