import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/inventory_groups"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "create", "update", "delete"],
  withSorting: true
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions(), withLoading)

export default store
