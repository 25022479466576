import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"

export default () => ({
  ADD_ITEM({ commit }, name) {
    const item = { id: currentTimestamp(), name }

    commit("ADD_ITEM", { item, prepend: true })
    return item
  }
})
