import { map, pick } from "lodash-es"
import { i18n } from "@/config"

const USER_ACTION_LOG_COMMON_TYPES = [
  {
    id: "shop_matching",
    name: i18n.t("logs.user_action_log.types.filters.shop_matching")
  },
  {
    id: "car_class_matching",
    name: i18n.t("logs.user_action_log.types.filters.car_class_matching")
  },
  {
    id: "ota_account_changes",
    name: i18n.t("logs.user_action_log.types.filters.ota_account_changes")
  },
  {
    id: "inventory_setting",
    name: i18n.t("logs.user_action_log.types.filters.inventory_setting")
  }
]

export const buildInitiatorsParams = list => map(list, el => pick(el, ["_skip", "id", "type", "_only_by_type"]))

export const buildUserActionLogTypes = sharedInventoryEnabled => {
  if (sharedInventoryEnabled) {
    return [
      ...USER_ACTION_LOG_COMMON_TYPES,
      {
        id: "inventory_group",
        name: i18n.t("logs.user_action_log.types.filters.inventory_group")
      },
      {
        id: "inventory_group_car_classes_matching",
        name: i18n.t("logs.user_action_log.types.filters.inventory_group_car_classes_matching")
      }
    ]
  } else {
    return [
      ...USER_ACTION_LOG_COMMON_TYPES,
      {
        id: "shop_car_classes_matching",
        name: i18n.t("logs.user_action_log.types.filters.shop_car_classes_matching")
      }
    ]
  }
}
