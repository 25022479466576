import StoreItemsModule from "@/config/store/StoreItemsModule"

import { format as dateFormat } from "date-fns"
import { map, isNull } from "lodash-es"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { buildInitiatorsParams } from "@/helpers/logs"

const mappedDateRange = dateRange => {
  return map(dateRange, date => {
    if (isNull(date)) {
      return null
    } else {
      return dateFormat(date, DATE_FNS_DATE_FORMAT)
    }
  })
}

export default (baseURI, dateRange = []) => {
  const mapFilters = filters => {
    return {
      initiators: buildInitiatorsParams(filters.initiators),
      shop_ids: map(filters.shops, "id"),
      inventory_group_ids: map(filters.inventoryGroups, "id"),
      car_class_ids: map(filters.carClasses, "id"),
      types: map(filters.types, "id"),
      created_at: mappedDateRange(filters.createdAt),
      date_range: mappedDateRange(filters.dateRange),
      search_value: filters.searchValue
    }
  }

  const store = new StoreItemsModule({
    baseURI,
    presetActions: ["fetch_post"],
    withFilters: {
      initiators: [],
      shops: [],
      inventoryGroups: [],
      carClasses: [],
      types: [],
      createdAt: [],
      dateRange,
      searchValue: ""
    },
    withPagination: true,
    mapFilters
  })

  return store
}
